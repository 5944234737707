import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import '../BlogPages';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Comments from '../../../../assets/comments.svg';
import Dots from '../../../../assets/three_dots.svg';
import Sliders from '../../../../assets/s3.svg';




function Posts() {
   
    const [posts, setPosts] = useState([
        {
          id: 1,
          title: 'The Art of Home Staging: How to Maximize Your Propertys Appeal',
          author: 'Premier Realty Group',
          tags: ['Tips for Buyers,', 'Mortgage,', 'Title & Escrow'],
          date: 'Publishes at\n06/10/23, 11:40am',
          comments: 100,
          editLink: '/edit/1'
        },
        {
          id: 2,
          title: 'Investing in Real Estate: A Comprehensive Guide for Beginners',
          author: 'Premier Realty Group',
          tags: ['Redux', 'State Management', 'React'],
          date: 'Publishes at\n06/10/23, 11:40am',
          comments: 3,
          editLink: '/edit/2'
        },

        {
            id: 3,
            title: 'The Impact of Technology on Real Estate: Whats Changing in 2023',
            author: 'Premier Realty Group',
            tags: ['Redux', 'State Management', 'React'],
            date: 'Publishes at\n06/10/23, 11:40am',
            comments: 3,
            editLink: '/edit/2'
          },

          {
            id: 4,
            title: 'Navigating the Real Estate Market in a Post-Pandemic World',
            author: 'Premier Realty Group',
            tags: ['Redux', 'State Management', 'React'],
            date: 'Publishes at\n06/10/23, 11:40am',
            comments: 3,
            editLink: '/edit/2'
          },

          {
            id: 5,
            title: 'The Art of Home Staging: How to Maximize Your Propertys Appeal',
            author: 'Premier Realty Group',
            tags: ['Redux', 'State Management', 'React'],
            date: 'Publishes at\n06/10/23, 11:40am',
            comments: 3,
            editLink: '/edit/2'
          },
          
          {
            id: 6,
            title: 'Investing in Real Estate: A Comprehensive Guide for Beginners',
            author: 'Premier Realty Group',
            tags: ['Redux', 'State Management', 'React'],
            date: 'Publishes at\n06/10/23, 11:40am',
            comments: 3,
            editLink: '/edit/2'
          },
          
          {
            id: 7,
            title: 'The Impact of Technology on Real Estate: Whats Changing in 2023',
            author: 'Premier Realty Group',
            tags: ['Redux', 'State Management', 'React'],
            date: 'Publishes at\n06/10/23, 11:40am',
            comments: 3,
            editLink: '/edit/2'
          },
          
          {
            id: 8,
            title: 'Navigating the Real Estate Market in a Post-Pandemic World',
            author: 'Premier Realty Group',
            tags: ['Redux', 'State Management', 'React'],
            date: 'Publishes at\n06/10/23, 11:40am',
            comments: 3,
            editLink: '/edit/2'
          }
        
         
          


      ]);
  
      const [dropdownOpen, setDropdownOpen] = useState(false);
  
      const toggleDropdown = (e, postId) => {
        e.preventDefault();
        e.stopPropagation();
        setDropdownOpen(dropdownOpen === postId ? false : postId);
      };
  
      const handleDelete = (postId) => {
        setPosts(currentPosts => currentPosts.filter(post => post.id !== postId));
      };
          
      /* Dropdown */

const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const [selectedOption, setSelectedOption] = useState('Filters');

const AgentdropdownOptions = [
    'Date',
    'Author',
    
];

const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setIsDropdownOpen(false);
};

const PostsDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
};

    return (
        <div className="dashboard-container">
            <div className="sidebar-main">
                <Sidebar />
            </div>
            <div className="header-dasboard main_container">
                <Header />
                <div className="dashboard post_page_only">
                    <div className="container-fluid main_container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mutual_blocks amp-pages">
                                    <h2>Blog Posts</h2>
                                    <div className="blog_add">
                                    <Link to="/single-blog"><div className="Add_new_blog btn update_btn">
                                    Add New
                                   </div></Link>
                                   </div>
                                   <div className="posting_area">
   


                                   <div className="row mb-3 padding">
  <div className="col-md-6 col-sm-12 d-flex justify-content-start align-items-center link_sett">
    All (10)  <Link to="/published" className="text-primary ms-1"><span>Published</span> (10)</Link>
  </div>
  <div className="col-md-6 col-sm-12 d-flex justify-content-end mku">
    <div className="search_area d-flex">
   <div className="search_bloxk"> <input type="search" className="form-control me-2" placeholder="Search by Title, Author, Tag" />
    </div>
   <div className="btn_block"><button className="btn btn-primary">Search</button></div>

    </div>
    <div className="filter_area">
    <div className="agent_dropdown">
        <div className={`drop_data ${isDropdownOpen ? 'open' : ''}`}>
            <div
                className="selected_option"
                onClick={PostsDropdown}
                style={{ backgroundImage: `url(${Sliders})` }}
            >
                {selectedOption}
            </div>
            {isDropdownOpen && (
                <div className="drop_selection">
                    {AgentdropdownOptions.map((option, index) => (
                        <p key={index} onClick={() => handleOptionSelect(option)}>
                            {option}
                        </p>
                    ))}
                </div>
            )}
        </div>
    </div>
        
    </div>
  </div>
</div>

      {/* Table */}
      <div className="row">
        <div className="col-lg-12">
          <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th scope="col" style={{ width: '40%' }}>Post Title</th>
                <th scope="col">Author</th>
                <th scope="col">Tags</th>
                <th scope="col">Date</th>
                <th scope="col">Comments</th>
                <th scope="col">Edit</th>
              </tr>
            </thead>
            <tbody>
            {posts.map((post) => (
    <tr key={post.id}>
      <td>{post.title}</td>
      <td className="author-tags">{post.author}</td>
      <td className="author-tags">{post.tags.join(', ')}</td>
      <td className="font_set">
        <div className="d-block">{post.date.split('\n')[0]}</div>
        <div className="d-block">{post.date.split('\n')[1]}</div>
      </td>
      <td className="font_set commnts">
        <img src={Comments} alt="Comments" />
        ({post.comments})
      </td>
      <td>
          <div className="dropdown dropper">
          <Link to="#" className="text-primary dropdown-toggle" role="button" id={`dropdownMenuLink-${post.id}`} onClick={(e) => toggleDropdown(e, post.id)}>
  <img src={Dots} alt="Edit" />
</Link>

            {dropdownOpen === post.id && (
            <div className={`dropdown-menu${dropdownOpen === post.id ? ' show' : ''}`} aria-labelledby={`dropdownMenuLink-${post.id}`}>
            <Link className="dropdown-item" to="/view-path">
            <span class="icon-eye1"></span> View
            </Link>
            <Link className="dropdown-item" to={post.editLink}>
            <span class="icon-Pencil1"></span> Edit
            </Link>
            <button className="dropdown-item" onClick={() => handleDelete(post.id)}>
            <span class="icon-Recycle-Bin"></span>  Delete
</button>


          </div>
          
            )}
          </div>
        </td>
    </tr>
  ))}
            </tbody>
          </table>
          </div>
        </div>
      </div>
    </div>








                                   
                                </div>












                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Posts;
