import React, { useState, useRef } from 'react';


import { Link } from 'react-router-dom';

import '../BlogPages';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Close from '../../../../assets/closer.png';
import defaultUploaderIcon from '../../../../assets/uploader.svg'; 

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';


/* add-delete-view */








function ManageTags() {
    const closeModalRef = useRef(null);
     
/* Tags_manage */

const [tags, setTags] = useState(['Default Tag 1', 'Default Tag 2']); 
const [newTagName, setNewTagName] = useState('Write tag name here');


const handleSaveClick = () => {
    if (newTagName.trim() !== '') {
      setTags([...tags, newTagName]);
      setNewTagName(''); 
    }
    
  };

  const handleInputChange = (e) => {
    setNewTagName(e.target.value);
  };

   

    return (
        <div className="dashboard-container">
            <div className="sidebar-main">
                <Sidebar />
            </div>
            <div className="header-dasboard main_container">
                <Header />
                <div className="dashboard post_page_only">
                    <div className="container-fluid main_container">
                        <div className="row">
                            <div className="col-lg-12">
                            <div className="mutual_blocks amp-pages manage_tags_set">
                                    <h2>Manage Tags</h2>
                                    
                                    <div className="blog_add">
  <button className="Add_new_blog btn update_btn" data-bs-toggle="modal" data-bs-target="#addTagModal">
    Add New
  </button>
</div>

{/* The Modal */}
<div className="modal fade tagger" id="addTagModal" tabIndex="-1" aria-labelledby="addTagModalLabel" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
  
      <div className="modal-body">
{/*       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
 */}


<div className="title_bar_setting tag_add_new">
    <h3>Tag Name</h3>
                          
                          <div className="form-group">
                           
                          <input
        type="text"
        className="form-control"
        value={newTagName}
        onChange={handleInputChange}
      />
                          </div>
                          
                       
                          </div>

<div className="two_btns">
<button className="btn update_btn saver" onClick={handleSaveClick}>
        Save
      </button>
    <button className="btn cancel_btn" data-bs-dismiss="modal" aria-label="Close">
     Cancel
    </button>
</div>


      </div>
 
    </div>
  </div>
</div>


{/* modal */}


                                   <p className="padd_app">Instructions: Tags are used to sort your content similar to a Category in the archive. Tags also provide a small search engine benefit as a keyword describing your content.</p>

                                   <div className="tag_list d-flex">
                                   {tags.map((tag, index) => (
        <div key={index} className="left_tags d-flex">
          <div className="tag_name">{tag} <span className="count_tags"> (0 Posts) </span></div>
          <div className="tag_btns d-flex">
            <div className="view_tag">
              <Link to="/view-path">
                <span className="icon-eye1"></span> 
              </Link>
            </div>
            <div className="tag_editing">
              <Link to="#">
                <span className="icon-Pencil1" data-bs-toggle="modal" data-bs-target="#addEditTagModal"></span> 
              </Link>
            </div>
            <div className="handle_delete">
              <button className="recycle_delete" onClick={() => {
                setTags(tags.filter((_, tagIndex) => index !== tagIndex));
              }}>
                <span className="icon-Recycle-Bin"></span> 
              </button>
            </div>
          </div>
        </div>
      ))}

      {/* edit-tag-modal */}
      <div className="modal fade tagger" id="addEditTagModal" tabIndex="-1" aria-labelledby="addEditTagModal" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
  
      <div className="modal-body">
{/*       <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
 */}


<div className="title_bar_setting tag_add_new">
    <h3>Edit Tag Name</h3>
                          
                          <div className="form-group">
                           
                          <input
        type="text"
        className="form-control"
        value={newTagName}
        onChange={handleInputChange}
      />
                          </div>
                          
                       
                          </div>

<div className="two_btns">
<button className="btn update_btn saver" onClick={handleSaveClick}>
        Save
      </button>
    <button className="btn cancel_btn" data-bs-dismiss="modal" aria-label="Close">
     Cancel
    </button>
</div>


      </div>
 
    </div>
  </div>
</div>
       {/* edit-tag-modal */}
    




                                   </div>
           



                                   <div className="two_btns">
    <button className="btn update_btn">
    Add Blog
    </button>
    <button className="btn cancel_btn" >
    Draft
    </button>
</div>



                                   
                                </div>













                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ManageTags;
