import React, { useState } from 'react';


import { Link } from 'react-router-dom';

import '../BlogPages';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Close from '../../../../assets/closer.png';
import defaultUploaderIcon from '../../../../assets/uploader.svg'; 








function SingleBlog() {
     

/* dropdown-js */

const [selectedTags, setSelectedTags] = useState([]); 
const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (tag) => {
    setSelectedTags((prevTags) => {
        if (prevTags.includes(tag)) {
            return prevTags.filter((t) => t !== tag);
        } else {
            return [...prevTags, tag];
        }
    });
};

// Function to toggle dropdown visibility
const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
};

const selectedTagsString = selectedTags.join(', ');


/* dropdown-js */


    return (
        <div className="dashboard-container">
            <div className="sidebar-main">
                <Sidebar />
            </div>
            <div className="header-dasboard main_container">
                <Header />
                <div className="dashboard post_page_only">
                    <div className="container-fluid main_container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mutual_blocks amp-pages single_posts blog-setting-page">
                                    <h2>Blog Settings</h2>
                                   
            <p>Instructions: Use the content editing window to compose and format your text as desired. You can add links, pictures, and even edit the html. When done, click “Save this post” to save your changes and view your post.</p>


<div className="post-data title_bar_setting ">
<div className="post_detail d-flex ">

<div className="right_post_detail">
<div className="single_entry">
        <label>
        Default Author Name
               
            <input 
                type="text" 
                className="form-control" 
                placeholder="Premier Reality Group" 
               
            /> </label>
       
       </div>




</div>

</div>

<div className="commenting"><h3>Comments</h3>
<div className="checks_sets">
<label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
        Allow Comments by Default on New Posts
    </label>
    <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
        Moderate Comments
    </label>
    <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
        Show Phone
    </label>
    <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
        Phone Required
    </label>
    <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
        Use Captcha
    </label>
<div className="area_comments d-flex">
    <div className="comments_per_page">Comments Per Page </div>
    <div className="comment_number"><input type="text" className="form-control" placeholder="10"/></div>
</div></div></div>



<div className="tags_selector timezone">
            <div className="single_entry select_single">
                <label>
                Timezone
                    <input 
                        type="text" 
                        className="form-control" 
                        value={selectedTagsString}
                        placeholder="Select Tags for this Post" 
                        readOnly
                        onClick={toggleDropdown} 
                    />
                </label>
              
                {isDropdownOpen && (
                    <div className="tags_dropdown">
                        {['Tips for Buyers', 'Tips for Sellers', 'Homeowner', 'Title & escrow', 'Mortgage', 'Mortgage Rates'].map((tag) => (
                            <div className="list_checks" key={tag}>
                                <label className="custom-checkbox-container">
                                    <input 
                                        type="checkbox" 
                                        className="custom-checkbox" 
                                        checked={selectedTags.includes(tag)}
                                        onChange={() => handleCheckboxChange(tag)}
                                    />
                                    <span className="checkmark"></span>
                                    {tag}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>

          {/* full-dropdown */}


<div className="feat_image">
<h3>Featured Images</h3>
<div className="checks_sets">
<label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
        Show featured images in posts
    </label>
    <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
        As mastheads with overlayed titles
    </label>
</div></div>


<div className="two_btns">
    <button className="btn update_btn">
   Update
    </button>
    <button className="btn cancel_btn" >
    Cancel
    </button>
</div>


</div>



                                   
                                </div>












                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleBlog;
