
import React, { useState } from 'react';
import './LoginRegister.css';
import Logo from '../../assets/logo.png';
import Arrow from '../../assets/arrow.png';



import { Link } from 'react-router-dom';




import Timer from '../../assets/timer.svg';
import Verified from '../../assets/verified.svg';


function EmailVerification() {

 


  


  return (
    <div className="login-register">
      <div className="login-header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-6 col-sm-6">
              <div className="logo"> <img src={Logo} alt="Logo" /></div>
            </div>
            <div className="col-lg-6 col-sm-6">
              <div className="mobile">(415) 144 0781</div>
            </div>
          </div>
        </div>
      </div>

      <div className="container container_bg">
      <div className="row ">
<div className="col-lg-4 offset-lg-1 com-sm-12 md_none" >
<div className="log_text_one">
Simplify Unlock Efficiency and Elevate
Your Real Estate Business with with
</div>
<div className="log_text_two">WOVNN</div>
<div className="log_image"><img src={Arrow} alt="Logo" /></div>
<div className="bottom_text_one">Get Started Today for Seamless Property Management</div>
<div className="bottom_multiple_text">
    <div className="b1">No card details required</div>
    <div className="b1 b2">Senior technical architect's assistance</div>
    <div className="b1 b3">Keep all the work secure</div>
</div>
</div>
<div className="col-lg-4 offset-lg-2 onset-lg-1 com-sm-12">

<div className="loginRegister">
           

<div className="email_verification">

<h3>Email Verification</h3>
<p>We noticed your email address has not been verified. By doing so, you will receive important notifications and information about your account.</p>
<img src={Timer} alt="timer"/>

<h4>Please Verify Your Email Address</h4>
<p>The email for your account is:</p>
<div className="email_verify">"info@testingwovnn.com”</div>
<p className="top_dne">If your email address is incorrect,</p>
<div className="update_link_email"><Link to="/email">please update it here.</Link></div>

</div>







        </div>


</div>
</div>
    
      </div>

   




    </div>
  );
}

export default EmailVerification;


