import React, { useState } from 'react';
import './TeamMates.css';
import { Link } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import Header from '../Header/Header';
import User1 from '../../../assets/user1.png';
import User2 from '../../../assets/user2.png';
import User3 from '../../../assets/user3.png';
import User4 from '../../../assets/user4.png';
import User5 from '../../../assets/user5.png';
import User6 from '../../../assets/user6.png';
import LeftArrow from '../../../assets/larrow.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Verified from '../../../assets/verified.svg';
import Close from '../../../assets/close.svg';
import Eye from '../../../assets/eye.svg';
import EyeSlash from '../../../assets/eye-slash.svg';
import Delete from '../../../assets/Recycle Bin.svg';
import Pendig from '../../../assets/hourglass.svg';
import Slide from '../../../assets/arrow_slide.svg';



/* teammates */

const teamMates = [
    { name: 'Kathryn Murphy', sales: 22, imgSrc: User1  },
    { name: 'Jane Cooper', sales: 18, imgSrc: User2 },
     { name: 'Theresa Webb', sales: 18, imgSrc: User3  },
     { name: 'Dianne Russell', sales: 18, imgSrc: User4  },
     { name: 'Jacob Jones', sales: 18, imgSrc: User5  },
     { name: 'Cody Fisher', sales: 18, imgSrc: User6  },
     { name: 'Kathryn Murphy', sales: 22, imgSrc: User1  },
     { name: 'Jane Cooper', sales: 18, imgSrc: User2 },
      { name: 'Theresa Webb', sales: 18, imgSrc: User3  },
      { name: 'Dianne Russell', sales: 18, imgSrc: User4  },
      { name: 'Jacob Jones', sales: 18, imgSrc: User5  },
      { name: 'Cody Fisher', sales: 18, imgSrc: User6  },
      { name: 'Kathryn Murphy', sales: 22, imgSrc: User1  },
      { name: 'Jane Cooper', sales: 18, imgSrc: User2 },
       { name: 'Theresa Webb', sales: 18, imgSrc: User3  },
       { name: 'Dianne Russell', sales: 18, imgSrc: User4  },
       { name: 'Jacob Jones', sales: 18, imgSrc: User5  },
       { name: 'Cody Fisher', sales: 18, imgSrc: User6  },
       { name: 'Kathryn Murphy', sales: 22, imgSrc: User1  },
       { name: 'Jacob Jones', sales: 18, imgSrc: User5  },
       { name: 'Cody Fisher', sales: 18, imgSrc: User6  },
       { name: 'Kathryn Murphy', sales: 22, imgSrc: User1  },
      
   
  ];



function TeamMates() {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 12;
    const totalResults = teamMates.length;
    const totalPages = Math.ceil(totalResults / itemsPerPage);

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTeammates = teamMates.slice(indexOfFirstItem, indexOfLastItem);

    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    const handleClick = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const nextPage = () => {
        setCurrentPage((prev) => Math.min(prev + 1, totalPages));
    };

    const prevPage = () => {
        setCurrentPage((prev) => Math.max(prev - 1, 1));
    };

    const handleInputChange = (e) => {
       
      };

      /* realtor-modal */

      const [isFormVisible, setIsFormVisible] = useState(true);
    
      const handleSendClick = () => {
          setIsFormVisible(false);
      };
    

    /*   teammate-new-functionality */

    const [teammates, setTeammates] = useState(teamMates); 
    const [hiddenBlocks, setHiddenBlocks] = useState({});

  
    const handleDelete = (mate) => {
        setTeammates(currentTeammates => currentTeammates.filter(t => t.name !== mate.name));
    };

    const toggleVisibility = (name) => {
        setHiddenBlocks(prevState => ({
            ...prevState,
            [name]: !prevState[name]
        }));
    };


    const TeamMate = ({ name, imgSrc, onDelete, onToggleVisibility }) => (
        <div className={`teammatess ${hiddenBlocks[name] ? 'hidden' : ''}`}>
            <img src={imgSrc} alt={name} className="teammate-img" />
            <div className="teammate-info">
                <h3>{name}</h3>
                <div className="two_icons d-flex">
                    <div className="single_icons" onClick={onToggleVisibility}>
                        <img src={hiddenBlocks[name] ? EyeSlash : Eye} alt="toggle visibility" />
                    </div>
                    <div className="single_icons" onClick={onDelete}>
                        <img src={Delete} alt="delete" />
                    </div>
                </div>
            </div>
        </div>
    );




    /* slider */

    const initialSlides = [{ /* Slide 1 data */ }];
  const [slides, setSlides] = useState(initialSlides);
    const [showSliderControls, setShowSliderControls] = useState(false);
    
    const addSlide = () => {
        const newSlide = { /* New slide data */ };
        setSlides([...slides, newSlide]);
        setShowSliderControls(true);
        setCurrentSlide(slides.length); 
    };


    const [currentSlide, setCurrentSlide] = useState(0);
    const totalSlides = 2; 


    const nextSlide = () => {
        setCurrentSlide(current => (current + 1) % slides.length);
    };
    
    const prevSlide = () => {
        setCurrentSlide(current => (current === 0 ? slides.length - 1 : current - 1));
    };

    return (
        <div className="dashboard-container">
            <div className="sidebar-main">
                <Sidebar />
            </div>
            <div className="header-dasboard main_container">
                <Header />
                <div className="dashboard">
                    <div className="container-fluid main_container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mutual_blocks team_mamet">
                                    <div className="heading_inline d-flex team-matew-page">
                                        <div className="left_head right_head">
                                            <h2>Realtor</h2>
                                            <div className="results_realtor">
                                            {`${indexOfFirstItem + 1}-${indexOfLastItem} of ${totalResults} Results`}
                                        </div></div>
                                        <div className="right_head">
                                          
                                            
<div className="realtor_search">
<div className="d-flex">
    <div className="search_area d-flex">
   <div className="search_bloxk"> <input type="search" className="form-control me-2" placeholder="Search by Name" />
    </div>
   <div className="btn_block"><button className="btn btn-primary">Search</button></div>

    </div>

    <div className="realtor_right">
   
                                   <div className="Add_new_blog btn update_btn" data-bs-toggle="modal" data-bs-target="#addRealtorModal">
                                    Add Realtor 
                                   </div>
                                  
    </div>

{/* The Modal */}
<div className="modal fade realtor_modal" id="addRealtorModal" tabIndex="-1" aria-labelledby="addRealtorModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    
                    <div className="modal-body">
                        {isFormVisible ? (
                            <div className="title_bar_setting tag_add_new slider_serting">

<div className="slades">
    {slides.map((slide, index) => (
        <div key={index} className={currentSlide === index ? "slides active" : "slides"}>
            <div className="realtor_form">
                                    <div className="real_row d-flex">
                                        <div className="real_left">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="First Name*"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="real_right">
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Last Name*"
                                                    onChange={handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="real_full_wifth">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Email Address* "
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                    <div className="real_full_wifth">
                                        <div className="form-group">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Phone No."
                                                onChange={handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
        </div>
    ))}
</div>




<button className="add_multiple_realt" onClick={addSlide}>+ Add multiple Realtor</button>



                                <div className="two_btns raltor_btns d-flex">
                                    <button className="btn update_btn saver raltor_btnss" onClick={handleSendClick}>
                                        Send
                                    </button>
                                    <button className="btn cancel_btn raltor_btnss" data-bs-dismiss="modal" aria-label="Close">
                                        Cancel
                                    </button>
                                </div>



                                <div className="dotting">
    {slides.length > 1 && slides.map((_, index) => (
        <div key={index} className={currentSlide === index ? "dot_list active" : "dot_list"} onClick={() => setCurrentSlide(index)}></div>
    ))}
</div>

      
{slides.length > 1 && (
    <div className="slides_arrows">
        <div className="previous_aro" onClick={prevSlide}> 
            <img src={Slide} className="close" />
        </div>
        <div className="next_aro" onClick={nextSlide}> 
            <img src={Slide} className="close" />
        </div>
    </div>
)}

                            </div>
                        ) : (
                            <div className="email_verification verification_done add_realtor_message">
                                <div className="cross_btn" data-bs-toggle="modal" data-bs-target="#addRealtorModal"> 
                        <img src={Close} className="close" />
                    </div>
                                <h3>Invitation Sent</h3>
                                <p>You've successfully extended an invitation to <div className="emailer_name"><strong>John Scott.</strong></div> </p>
                                <img src={Verified} alt="verified"/>
                                <h4>Await their response and welcome them to your real estate network.</h4>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>

{/* modal */}



  
  </div>

</div>
                                        </div>
                                    </div>


                                    <div className="teammate_list d-flex team-mate-page-only">
    {teammates.map((mate) => (
        <TeamMate
            key={mate.name}
            name={mate.name}
            imgSrc={mate.imgSrc}
            onDelete={() => handleDelete(mate.name)} 
            onToggleVisibility={() => toggleVisibility(mate.name)} 
        />
    ))}
</div>

                                    <div className="custom-pagination">
                                        <ul>
                                            <li className="left_page" onClick={prevPage}>
                                                <span>
                                                    <img src={LeftArrow} alt="arrow" />
                                                </span>{' '}
                                                Prev
                                            </li>
                                            
                                            {pageNumbers.map((number) => (
                                                <li
                                                    key={number}
                                                    onClick={() => handleClick(number)}
                                                    className={currentPage === number ? 'active-page' : null}
                                                >
                                                    {number}
                                                </li>
                                            ))}
                                            
                                            <li className="right_page" onClick={nextPage}>
                                                Next{' '}
                                                <span>
                                                    <img src={LeftArrow} alt="arrow" className="rotate_img" />
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TeamMates;
