import React, { useState } from 'react';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; 

import { Link } from 'react-router-dom';

import '../BlogPages';
import Sidebar from '../../Sidebar/Sidebar';
import Header from '../../Header/Header';
import Close from '../../../../assets/closer.png';
import defaultUploaderIcon from '../../../../assets/uploader.svg'; 
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';








function SingleBlog() {
       /* datepicker */

    const [startDate, setStartDate] = useState(new Date());


    /* datepicker */

/* link-edit */
  
  const [isReadOnly, setIsReadOnly] = useState(true);

  // Function to toggle readOnly status
  const toggleEdit = () => {
      setIsReadOnly(!isReadOnly);
  };

/* link-edit */

/* image-uploaded */

const [uploadedFeatured, setUploadedFeatured] = useState(null);

const handleFeaturedUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type.substr(0, 5) === 'image') {
        if (uploadedFeatured) {
            URL.revokeObjectURL(uploadedFeatured);
        }
        setUploadedFeatured(URL.createObjectURL(file));
    } else {
        setUploadedFeatured(null);
    }
};

const handleFeaturedRemove = () => {
    if (uploadedFeatured) {
        URL.revokeObjectURL(uploadedFeatured);
        setUploadedFeatured(null);
    }
};


/* dropdown-js */

const [selectedTags, setSelectedTags] = useState([]); 
const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (tag) => {
    setSelectedTags((prevTags) => {
        if (prevTags.includes(tag)) {
            return prevTags.filter((t) => t !== tag);
        } else {
            return [...prevTags, tag];
        }
    });
};

// Function to toggle dropdown visibility
const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
};

const selectedTagsString = selectedTags.join(', ');


/* dropdown-js */



/* content-editor */

   /* content-editor */
   const [editorContent, setEditorContent] = useState('');

   const handleEditorChange = (content) => {
       setEditorContent(content);
   };

   // Correct placement for modules configuration
   const modules = {
       toolbar: [
           // Custom buttons for Undo and Redo would be implemented here (requires custom implementation)
           [{ 'header': ['1', '2', '3', '4', '5', '6', false] }], // Headings list
           [{ 'align': [] }], // Text alignment
           ['bold', 'italic', 'underline'], // B, I, U
           [{ 'list': 'ordered'}, { 'list': 'bullet' }], // Listings
           [{ 'color': [] }, { 'background': [] }], // Color selectors
           ['link', 'image', 'blockquote'], // Link, Image, Quote
           // ... add other toolbar options as needed
       ],
       // ... add other modules if needed
   };


/* content-editor */

   

    return (
        <div className="dashboard-container">
            <div className="sidebar-main">
                <Sidebar />
            </div>
            <div className="header-dasboard main_container">
                <Header />
                <div className="dashboard post_page_only">
                    <div className="container-fluid main_container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="mutual_blocks amp-pages single_posts">
                                    <h2>Blog Posts</h2>
                                   
            <p>Instructions: Use the content editing window to compose and format your text as desired. You can add links, pictures, and even edit the html. When done, click “Save this post” to save your changes and view your post.</p>


<div className="post-data title_bar_setting ">
<div className="post_detail d-flex ">
<div className="left_post_detail">
<div className="single_entry">
        <label>
        Title
               
            <input 
                type="text" 
                className="form-control" 
                placeholder="The Art of Home Staging: How to Maximize Your Property's Appeal" 
               
            /> 
          
            </label>
            <div className="edit_link d-flex">
                <div className="left_link_edit">
                    <input 
                        type="text"
                        className="edit_control"
                        defaultValue="https://www.allprofessionalsre.com/banks-real-estate-all-professional-real-estates"
                        readOnly={isReadOnly}
                        onBlur={() => setIsReadOnly(true)} 
                    />
                </div>
                <div className="edit_icon" onClick={toggleEdit}>
                    <span className="icon-Pencil1"></span>
                </div>
            </div>
        </div>
</div>
<div className="right_post_detail">
<div className="single_entry">
        <label>
        Author Name
               
            <input 
                type="text" 
                className="form-control" 
                placeholder="Premier Reality Group" 
               
            /> </label>
       
       </div>
</div>

</div>


<div className="editor">

<ReactQuill 
      value={editorContent} 
      onChange={handleEditorChange} 
      modules={modules}
    />



</div>


<div className="tefe d-flex">

<div className="l_tefe">
    {/* full-dropdown */}
<div className="tags_selector">
            <div className="single_entry select_single">
                <label>
                    Tags
                    <input 
                        type="text" 
                        className="form-control" 
                        value={selectedTagsString}
                        placeholder="Select Tags for this Post" 
                        readOnly
                        onClick={toggleDropdown} 
                    />
                </label>
              
                {isDropdownOpen && (
                    <div className="tags_dropdown">
                        {['Tips for Buyers', 'Tips for Sellers', 'Homeowner', 'Title & escrow', 'Mortgage', 'Mortgage Rates'].map((tag) => (
                            <div className="list_checks" key={tag}>
                                <label className="custom-checkbox-container">
                                    <input 
                                        type="checkbox" 
                                        className="custom-checkbox" 
                                        checked={selectedTags.includes(tag)}
                                        onChange={() => handleCheckboxChange(tag)}
                                    />
                                    <span className="checkmark"></span>
                                    {tag}
                                </label>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>

          {/* full-dropdown */}
    <div className="date_published"> 
    <div className="single_entry date_icon">

    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      showTimeSelect
      dateFormat="Pp"
      className="all_dates"

    />
       {/*  <label>
        Published Date and Time
               
            <input 
                type="text" 
                className="form-control" 
                readOnly={isReadOnly}
                placeholder="07-10-23, 10:00am" 
               
            /> 
          
            </label> */}
           
        </div>
    </div>

    <div className="two labels_checks d-flex">
    <label className="custom-checkbox-container">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
        Allow Comments
    </label>
    <label className="custom-checkbox-container mdl">
        <input type="checkbox" className="custom-checkbox" />
        <span className="checkmark"></span>
        Publish this post
    </label>
</div>


</div>
<div className="r_tefe">
<div className="right_link_comp">

<h3>Featured Image</h3>
<div className="favicon_uploader">
      {uploadedFeatured ? (
        <>
          <div className="close_fav" onClick={handleFeaturedRemove}>
            <img src={Close} alt="remove" />
          </div>
          <img src={uploadedFeatured} alt="Uploaded featured Icon" className="Featured_image"/>
        </>
      ) : (
        <label htmlFor="featured" className="favicon_label">
          <img src={defaultUploaderIcon} alt="Featured Uploader" />
          <p>Upload Featured Image</p>
        </label>
      )}
      <input
        type="file"
        accept="image/*"
        onChange={handleFeaturedUpload}
        className="favicon_input"
        id="featured"
      />
    </div>
</div>

</div>

</div>

<div class="center_border"></div>

<div className="tags_set">

<div className="title_bar_setting">
                                    <h3>Meta Title</h3>
                                    
                                    <div className="form-group">
                                        <label htmlFor="callMeNowPhone">This meta description will be used for your homepage, and as the default description for each of your pages, unless you override it by providing a page specific description in its Advanced Page Settings.</label>

                                        <div className="d-flex custom_fcontrol">
    <div className="left_farea">           <input
                                        type="text"
                                        id="metaDescriptionInput"
                                        className="form-control"
                                       
                                        placeholder="Banks Real Estate | All Professional Real Estates"
                                    /></div>
    <div className="right_farea">Should be no more than 8-10 words</div>
</div>

                                        
                             
                                    </div>
                                 
                                    </div>
                                    <div className="title_bar_setting second_input">
                                    <h3>Meta Description</h3>
                                    
                                    <div className="form-group">
                                        <label htmlFor="callMeNowPhone">This meta description will be used for your homepage, and as the default description for each of your pages, unless you override it by providing a page specific description in its Advanced Page Settings.</label>

                                        <div className="d-flex custom_fcontrol">
    <div className="left_farea">           <input
                                        type="text"
                                        id="metaDescriptionInput"
                                        className="form-control"
                                       
                                        placeholder="Banks Homes for Sale, Property Search in Banks"
                                    /></div>
    <div className="right_farea">Should be no more than 8-10 words</div>
</div>

                                        
                             
                                    </div>
                                 
                                    </div>

</div>

<div className="two_btns">
    <button className="btn update_btn">
    Add Blog
    </button>
    <button className="btn cancel_btn" >
    Draft
    </button>
</div>


</div>



                                   
                                </div>












                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SingleBlog;
